<template>
  <Layout>
    <div class="app-prize-edit">
      <a-page-header title="抽奖" />
      <a-form-model :model="form" ref="form">
        <div class="adv-form">
          <a-row :gutter="24">
            <a-col :span="18">
              <a-row :gutter="24">
                <a-col :span="12">
                  <a-form-model-item
                    required
                    label="活动名称"
                    prop="title"
                    :rules="[
                      {
                        required: true,
                        message: '活动名称必填'
                      }
                    ]"
                  >
                    <a-input v-model="form.title" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item
                    required
                    label="活动开始时间"
                    prop="startTime"
                    :rules="{
                      required: true,
                      message: '开始时间必填'
                    }"
                  >
                    <a-date-picker
                      v-model="form.startTime"
                      valueFormat="YYYY-MM-DD HH:mm"
                      format="YYYY-MM-DD HH:mm"
                      :disabled-date="disabledStartDate"
                      :show-time="{ format: 'h:mm' }"
                    />
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item
                    required
                    label="活动结束时间"
                    prop="endTime"
                    :rules="{
                      required: true,
                      message: '结束时间必填'
                    }"
                  >
                    <a-date-picker
                      v-model="form.endTime"
                      valueFormat="YYYY-MM-DD HH:mm"
                      format="YYYY-MM-DD HH:mm"
                      :disabled-date="disabledEndDate"
                      :show-time="{ format: 'h:mm' }"
                    />
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-row :gutter="24">
                <a-col :span="6">
                  <a-form-model-item required label="参与次数" prop="numType">
                    <a-radio-group v-model="form.numType">
                      <a-radio :value="1">1天N次</a-radio>
                      <a-radio :value="2">1人N次</a-radio>
                    </a-radio-group>
                  </a-form-model-item>
                </a-col>
                <a-col :span="6">
                  <a-form-model-item
                    required
                    label="登录参与次数"
                    prop="num"
                    :rules="[
                      {
                        required: true,
                        message: '登录参与次数必填'
                      }
                    ]"
                  >
                    每人{{ form.numType === 1 ? "每天" : "一共" }}抽
                    <a-input-number v-model="form.num" :min="1" :max="100" />
                    次
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-model-item
                    required
                    label="显示中奖名单"
                    prop="showAward"
                  >
                    <a-switch v-model="form.showAward" />
                  </a-form-model-item>
                </a-col>
              </a-row>

              <a-row :gutter="24">
                <a-col :span="12">
                  <a-form-model-item
                    required
                    label="分享标题"
                    prop="shareTitle"
                    :rules="[
                      {
                        required: true,
                        message: '分享标题必填'
                      }
                    ]"
                  >
                    <span slot="help"
                      >动态替换：
                      <a-tag color="pink">%昵称%</a-tag>
                    </span>
                    <a-input v-model="form.shareTitle" />
                  </a-form-model-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item
                    required
                    help="建议长宽比：5:4，例如：600 x 480"
                    prop="shareImg"
                  >
                    <span slot="label">
                      分享封面&nbsp;
                      <a-tooltip title="不设置封面时默认使用屏幕截图">
                        <a-icon type="question-circle-o" />
                      </a-tooltip>
                    </span>
                    <a-upload
                      :data="{ index: 889 }"
                      :action="UPLOAD_URL"
                      :headers="{ t: getToken }"
                      withCredentials
                      accept="image/*"
                      :showUploadList="false"
                      @change="handleUpload"
                      :before-upload="beforeUpload"
                    >
                      <img
                        v-if="form.shareImg"
                        class="upload-image-small"
                        :src="form.shareImg"
                      />
                      <a-button-group>
                        <a-button>
                          <a-icon type="upload" />
                          {{ form.shareImg ? "更换" : "上传" }}图片
                        </a-button>
                      </a-button-group>
                    </a-upload>
                  </a-form-item>
                </a-col>
              </a-row>
            </a-col>
            <a-col :span="6">
              <a-form-model-item label="活动规则" prop="desc">
                <a-textarea
                  :maxLength="500"
                  placeholder="请填写抽奖活动规则"
                  v-model="form.desc"
                  :auto-size="{ minRows: 4, maxRows: 8 }"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>

        <div class="tool">
          <a-button :disabled="this.form.rows.length >= 8" @click="addRow">
            <a-icon type="plus" /> 添加奖项
          </a-button>
        </div>

        <table class="prize-items">
          <thead class="">
            <th class="icon">图片</th>
            <th class="name">名称</th>
            <th class="type">类型</th>
            <th class="prize">
              奖品
              <a-tooltip
                title="商品中奖后，系统将自动创建并发放一张与该商品的无门槛优惠券"
              >
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </th>
            <th class="weixin">
              客服微信号
              <a-tooltip title="红包、礼品等通过联系客服微信领奖">
                <a-icon type="question-circle-o" />
              </a-tooltip>
            </th>
            <th class="num">剩余份数</th>
            <th class="rate">中奖率</th>
            <th class="action">操作</th>
          </thead>
          <tbody>
            <tr class="row" v-for="(item, index) in form.rows" :key="item.key">
              <td>
                <!-- 图片 -->
                <a-upload
                  :data="{ index }"
                  listType="picture-card"
                  :action="UPLOAD_URL"
                  :headers="{ t: getToken }"
                  withCredentials
                  accept="image/*"
                  :showUploadList="false"
                  @change="handleUpload"
                  :before-upload="beforeUpload"
                >
                  <img v-if="item.icon" :src="item.icon" style="width:50px" />
                  <div v-else>
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
              </td>
              <td>
                <a-input
                  v-model="item.name"
                  :max-length="6"
                  placeholder="最多6个字符"
                />
              </td>
              <td>
                <!-- 类型 -->
                <a-select
                  v-model="item.type"
                  @change="changeType(index)"
                  placeholder="请选择"
                >
                  <a-select-option :value="1">优惠券</a-select-option>
                  <a-select-option :value="2">商品</a-select-option>
                  <a-select-option :value="10">红包</a-select-option>
                  <a-select-option :value="15">礼品</a-select-option>
                  <a-select-option :value="16">自定义</a-select-option>
                </a-select>
              </td>
              <td>
                <!-- 优惠券 -->
                <a-select
                  v-if="item.type == 1"
                  v-model="item.couponId"
                  @change="changeCoupon(index)"
                  placeholder="请选择"
                  notFoundContent="请在小商店后台创建券"
                >
                  <a-select-option
                    v-for="c in couponList"
                    :key="c.id"
                    :value="c.id"
                    >{{ c.name }}
                  </a-select-option>
                </a-select>

                <!-- 商品 -->
                <a-select
                  show-search
                  :filter-option="filterOption"
                  v-if="item.type == 2"
                  v-model="item.productId"
                  @change="changeProduct(index)"
                  placeholder="请选择"
                >
                  <a-select-option
                    v-for="p in productList"
                    :key="p.id"
                    :value="p.id"
                    >{{ p.title }}
                  </a-select-option>
                </a-select>

                <!-- 红包 -->
                <template v-if="item.type === 10">
                  <a-input-number
                    v-model="item.money"
                    @change="changeMoney(index)"
                    placeholder="红包金额"
                    :min="0.1"
                    :max="99999"
                    :step="0.01"
                  />
                  元
                </template>

                <!-- 礼品 -->
                <!-- 自定义 -->
                <a-input
                  v-if="item.type >= 15"
                  v-model="item.prizeName"
                  placeholder="奖品名称, 最多20个字符"
                  :max-length="20"
                />
              </td>
              <td>
                <a-input
                  v-if="item.type >= 10"
                  v-model="item.weixin"
                  placeholder="客服微信号"
                  :max-length="40"
                />
              </td>
              <td>
                <a-input-number
                  style="width:70px;"
                  v-model="item.num"
                  :min="1"
                  :max="99999"
                />
              </td>
              <td>
                <a-input-number
                  style="width:70px;"
                  v-model="item.rate"
                  :min="1"
                  :max="99"
                />
                %
              </td>
              <td>
                <a-button type="danger" @click="delRow(index)">
                  删除
                </a-button>
              </td>
            </tr>
            <!-- 无奖项 -->
            <tr v-if="form.rows.length <= 0">
              <td colspan="8">
                <a-empty />
              </td>
            </tr>
            <!-- 未中奖 -->
            <tr v-if="this.form.rows.length < 8">
              <td>
                <!-- 图片 -->
                <a-upload
                  :data="{ index: 999 }"
                  listType="picture-card"
                  :action="UPLOAD_URL"
                  :headers="{ t: getToken }"
                  withCredentials
                  accept="image/*"
                  :showUploadList="false"
                  @change="handleUpload"
                  :before-upload="beforeUpload"
                >
                  <img
                    v-if="form.lostIcon"
                    :src="form.lostIcon"
                    style="width:50px"
                  />
                  <div v-else>
                    <a-icon type="plus" />
                    <div class="ant-upload-text">
                      上传
                    </div>
                  </div>
                </a-upload>
              </td>
              <td>
                <a-input
                  v-model="form.lostName"
                  :max-length="6"
                  placeholder="最多6个字符"
                />
              </td>
              <td>
                未中奖
              </td>
              <td>
                <a-input
                  v-model="form.lostMsg"
                  :max-length="20"
                  placeholder="最多20个字符"
                />
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tbody>
        </table>

        <div style="height:100px" />

        <div class="footer">
          <a-button type="primary" html-type="submit" @click="submitForm">
            保存
          </a-button>
          <a-button style="margin-left: 10px" @click="cancel">
            取消
          </a-button>
        </div>
      </a-form-model>
    </div>
  </Layout>
</template>

<script>
import Layout from "@/layout/index.vue";

const moment = require("moment");

// import ColorPicker from "@/components/color-picker";

const PrizeSvc = require("@/service/prize");

import loadDataMixin from "@/mixins/loadData";
import productMixin from "@/mixins/product";
import couponMixin from "@/mixins/coupon";
import textMixin from "@/mixins/text";
import uploadMixin from "@/mixins/upload";

const DEFAULT_PRIZE = {
  1: {
    icon: "https://store.xinsailei.com/prize/coupon.png"
  },
  10: {
    icon: "https://store.xinsailei.com/prize/hongbao.png"
  },
  15: {
    icon: "https://store.xinsailei.com/prize/gift.png"
  }
};

export default {
  name: "PrizeEdit",

  data() {
    return {
      mode: "add", // 模式：add 批量新增，edit 单个编辑

      form: {
        title: "",

        startTime: null,
        endTime: null,

        numType: 1,
        num: 3,

        lostName: "谢谢参与",
        lostIcon: "https://store.xinsailei.com/prize/lost.png",
        lostMsg: "很遗憾，没有中奖",

        showAward: true,
        desc: `活动时间：
2021-XX-XX 至 2021-XX-XX；

参与次数：
每人可抽奖3次；每分享1人参与可额外抽奖1次（不超过3次）；

奖品设置：


发行方：
`,

        shareTitle: "在吗？一起来抽超级福利大奖吧！",
        shareImg: "https://store.xinsailei.com/prize/gift-share.png",

        rows: []
      }
    };
  },

  computed: {},

  mixins: [loadDataMixin, productMixin, couponMixin, textMixin, uploadMixin],

  methods: {
    handleUpload({ file }) {
      if (file.status != "done") return;

      const { code, msg, data } = file.response;
      if (code == "0") {
        const { index, url } = data;

        switch (index) {
          // 分享截图
          case 889:
            this.form.shareImg = url;
            break;
          //  未中奖
          case 999:
            this.form.lostIcon = url;
            break;
          // banner
          case 888:
            this.form.banner = url;
            break;
          default:
            {
              const res = this.form.rows[index];
              this.$set(this.form.rows, index, {
                ...res,
                icon: url
              });
            }
            break;
        }
      } else {
        this.$message.error("上传失败，错误：" + msg);
      }
    },

    addRow() {
      // 最多8个
      if (this.form.rows.length >= 8) {
        this.$message.info("最多8个奖项");
        return;
      }

      const type = 1;

      // 默认图标
      const prizeName = "";
      let { icon, name } = DEFAULT_PRIZE[type] || {};
      icon = icon || "";
      name = name || "";

      this.form.rows.push({
        type,
        icon,
        name,
        prizeName,
        num: "",
        rate: ""
      });
    },
    delRow(index) {
      this.form.rows.splice(index, 1);
    },
    changeType(index) {
      const res = this.form.rows[index];
      const { type } = res;

      let { icon } = DEFAULT_PRIZE[type] || {};
      icon = icon || "";

      this.$set(this.form.rows, index, {
        ...res,
        icon
      });
    },

    changeCoupon(index) {
      const res = this.form.rows[index];
      const { couponId } = res;

      const prizeName =
        "优惠券:" + this.couponList.filter(x => x.id === couponId)[0].name;
      this.$set(this.form.rows, index, {
        ...res,
        prizeName
      });
    },
    changeProduct(index) {
      const res = this.form.rows[index];
      const { productId } = res;

      const prizeName =
        "商品:" + this.productList.filter(x => x.id === productId)[0].title;
      this.$set(this.form.rows, index, {
        ...res,
        prizeName
      });
    },
    changeMoney(index) {
      const res = this.form.rows[index];
      const { money } = res;

      const prizeName = "红包:" + money;
      this.$set(this.form.rows, index, {
        ...res,
        prizeName
      });
    },

    // 活动日期
    disabledStartDate(startValue) {
      const endValue = this.form.endTime;
      if (!startValue || !endValue) {
        return false;
      }
      return startValue.valueOf() > moment(endValue).valueOf();
    },
    disabledEndDate(endValue) {
      const startValue = this.form.startTime;
      if (!endValue || !startValue) {
        return false;
      }
      return moment(startValue).valueOf() >= endValue.valueOf();
    },

    submitForm() {
      this.$refs.form.validate(async valid => {
        if (valid) {
          let {
            id,
            title,
            startTime,
            endTime,
            numType,
            num,

            lostName,
            lostIcon,
            lostMsg,

            showAward,
            desc,

            shareTitle,
            shareImg,

            rows
          } = this.form;

          if (!shareImg) {
            this.$message.error("请上传分享封面");
            return;
          }

          if (rows.length <= 0) {
            this.$message.error("请至少添加一个奖品");
            return;
          }

          for (const row of rows) {
            let { type, name, icon, money, prizeName, weixin, num, rate } = row;

            if (!icon) {
              this.$message.error("请上传奖品图片");
              return;
            }

            if (!name) {
              this.$message.error("请填写奖品名称");
              return;
            }

            if (type === 10) {
              if (!money) {
                this.$message.error("请输入红包金额");
                return;
              }
            }

            if (!prizeName) {
              switch (type) {
                case 1:
                  this.$message.error("请选择优惠券");
                  break;
                case 2:
                  this.$message.error("请选择商品");
                  break;
                default:
                  this.$message.error("请填写完整奖品信息");
                  break;
              }
              return;
            }

            if (type >= 10) {
              if (!weixin) {
                this.$message.error("请填写客服微信号");
                return;
              }
            }

            num = parseInt(num);
            if (isNaN(num) || num <= 0 || num > 99999) {
              this.$message.error("请输入奖品数量");
              return;
            }
            rate = parseFloat(rate);
            if (isNaN(rate) || rate < 0.1 || rate >= 100) {
              this.$message.error("请输入中奖率");
              return;
            }
          }

          const json = JSON.stringify(rows);
          try {
            let val = {
              title,
              startTime,
              endTime,

              numType,
              num,

              lostName,
              lostIcon,
              lostMsg,

              showAward,
              desc,

              shareTitle,
              shareImg,

              json
            };

            if (this.mode === "edit") {
              val.id = id;
            }
            await PrizeSvc.createOrUpdate(val);

            const msg = this.mode === "add" ? "创建成功" : "保存成功";
            this.$message.info(msg);
            this.$router.replace({ name: "PrizeList" });
          } catch (err) {
            console.error(err);
            this.$message.error("创建活动失败，错误：" + err.message);
          }
        } else {
          console.log("error submit!!");

          return false;
        }
      });
    },
    cancel() {
      this.$router.replace({ name: "PrizeList" });
    }
  },

  async mounted() {
    let { id } = this.$route.query || {};
    this.mode = id ? "edit" : "add";

    if (id) {
      try {
        id = parseInt(id);
        let res = await PrizeSvc.detail(id);

        let showAward = !!res.showAward;
        let rows = res.lines;
        this.form = { ...res, showAward, rows };
      } catch (err) {
        console.error(err);
        this.$message.error("加载活动失败，错误：" + err.message);
      }
    }
  },

  components: {
    Layout
    // ColorPicker
  }
};
</script>

<style lang="scss">
.app-prize-edit {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - var(--header-height));
  background: #f0f2f5;
  padding: 24px 24px 0;

  .adv-form {
    padding: 14px;
    margin-bottom: 10px;
    background: #fbfbfb;
    border: 1px solid #d9d9d9;

    table {
      width: 100%;

      img {
        width: 100px;
        max-height: 100px;
      }
    }
  }

  .tool {
    padding: 24px 24px;
    background: #fff;
    display: flex;
    justify-content: space-between;
  }

  .upload-image-small {
    width: 40px;
    height: 32px;
    margin-right: 10px;
  }

  .table-wrap {
    background: #fff;
  }

  .product-img {
    width: 60px;
    max-height: 60px;
  }

  .footer {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 99;
    background: #fff;
    padding: 10px;
    text-align: center;
  }

  .prize-items {
    width: 100%;
    color: rgba(0, 0, 0, 0.65);
    font-size: 14px;
    line-height: 1.5;
    margin-bottom: 24px;

    .ant-upload {
      width: 64px;
      height: 64px;
    }

    tr {
      background: #fff;
      transition: background 0.3s;

      &:hover {
        background: #e6f7ff;
      }
    }

    th {
      color: rgba(0, 0, 0, 0.85);
      font-weight: 500;
      text-align: left;
      background: #fafafa;
      border-bottom: 1px solid #e8e8e8;
      transition: background 0.3s ease;
      white-space: nowrap;
      height: 53px;
    }

    th,
    td {
      padding: 16px;
      transition: background 0.3s;
      border-bottom: 1px solid #e8e8e8;
      white-space: nowrap;

      .ant-select {
        width: 100%;
      }
    }

    .icon {
      width: 84px;
      text-align: center;
    }
    .name {
      width: 150px;
    }
    .type {
      width: 120px;
    }
    .prize {
      flex: 1;
    }
    .weixin {
      width: 180px;
    }
    .num {
      width: 60px;
      text-align: right;
    }
    .rate {
      width: 60px;
      text-align: right;
    }
    .action {
      width: 60px;
    }
  }
}
</style>
